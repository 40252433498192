<template>
  <div class="backoffice-view list-view users">
		<div class="page-header flex-v">
			<h1>Actividad</h1>
			<p v-if="user">
				<span>{{ `${user.name} ${user.lastName}` }}</span>
				<span> - </span>
				<span>{{ user?.dni?.length > 0 ? user.dni : 'Sin DNI cargado' }}</span>
			</p>
		</div>
		<div class="loading" v-if="useBackofficeStore().loading">cargando...</div>
		<div
			class="table-container"
			v-else-if="userActivity.length > 0"
		>
			<div class="table-tools">
				<div class="filters">
					<div class="filter">
						<select v-model="filters.filterEventType">
							<option value="" selected>Tipo de actividad</option>
							<option
								v-for="event in eventTypes"
								:key="event"
								:value="event"
							>{{ event }}</option>
						</select>
					</div>
					<div class="filter">
						<select v-model="filters.filterEntityId">
							<option value="" selected>ID de entidad</option>
							<option
								v-for="id in entityIds"
								:key="id"
								:value="id"
							>{{ id }}</option>
						</select>
					</div>
					<div class="filter">
						<input type="date" v-model="filters.filterDate">
					</div>
				</div>
			</div>
			<table v-if="filteredData.length > 0">
				<thead>
					<th>Entidad</th>
					<th>Tipo de actividad</th>
					<th></th>
					<th>Fecha</th>
					<th>Hora</th>
				</thead>
				<tbody>
					<tr
						v-for="event in filteredData"
						:key="event.logActivityId"
					>
						<td>{{ event.entity }}</td>
						<td>{{ event.activity }}</td>
						<td>
							{{ 
								event.activity == 'ARTICLE_LEAVE' ? 
									JSON.parse(event.value).read ?
										JSON.parse(event.value).read
										: ''
									: event.entity == 'Video' 
										? event.value 
										: ''
							}}
						</td>
						<td>{{ getFormattedDate(event.creationDate) }}</td>
						<td>{{ getFormattedTime(event.creationDate) }}</td>
						<!-- <td>
							<div class="btn-container">
								<button>
									<router-link
										:to="{
											name: 'UserActivityView',
											query: {cuid: user.cuid}
										}"
									>
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<mask id="mask0_6_1147" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
											<rect width="24" height="24" fill="#D9D9D9"/>
											</mask>
											<g mask="url(#mask0_6_1147)">
											<path d="M5 19H6.4L15.025 10.375L13.625 8.975L5 17.6V19ZM19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z" fill="#595959"/>
											</g>
										</svg>
									</router-link>
								</button>
							</div>
						</td> -->
					</tr>
				</tbody>
			</table>
			<div
				v-else
			>
				<p>No hay resultados.</p>
			</div>
		</div>
		<div
			v-else
		>
			<p>No hay actividad registrada.</p>
		</div>
  </div>
</template>

<script setup>
import { useBackofficeStore } from '@/stores/index.js'
</script>

<script>
export default {
  name: 'UserActivityList',
	data() {
		return {
			user: undefined,
			userActivity: [],
			filters: {
				filterEventType: '',
				filterDate: '',
				filterEntityId: ''
			}
		}
	},

	computed: {
		users() {
			return useBackofficeStore().users
		},

		filteredData() {
			var filteredActivity = this.userActivity
			if (this.filters.filterEventType) {
				filteredActivity = filteredActivity.filter((act) => act.activity == this.filters.filterEventType)
			}

			if (this.filters.filterDate) {
				filteredActivity = filteredActivity.filter((act) => act.creationDate?.split('T')[0] == this.filters.filterDate)
			}

			if (this.filters.filterEntityId) {
				filteredActivity = filteredActivity.filter((act) => act.entityId == this.filters.filterEntityId)
			}

			return filteredActivity
    },

		eventTypes() {
			return [...new Set(useBackofficeStore().activity?.map(act => act.activity))]
		},

		entityIds() {
			return [...new Set(useBackofficeStore().activity?.map(act => act.entityId))]
		}
	},

	watch: {
    users: {
      handler(newUsers) {
        if (newUsers.length > 0 && this.$route.query.user) {
					this.user = newUsers.find(user => user.userId == this.$route.query.user)
					this.fetchUserActivity(this.user.cuid)
        }
      },
      immediate: true, // Optional: triggers the handler immediately on component creation
    },
  },

	methods: {
		fetchUserActivity(cuid) {
			fetch(process.env.VUE_APP_URL_API + 'api/logActivity/app/getByUser/' + cuid, {
				method: 'GET',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
			})
			.then(response => response.json())
			.then(data => this.userActivity = data)
			.catch(error => console.error('Error:', error))
		},

		getFormattedDate(datetime) {
			if (datetime) {
				var split = datetime.split('T')
				return split[0]
			}
		},

		getFormattedTime(datetime) {
			if (datetime) {
				var split = datetime.split('T')
				return split[1].split('.')[0]
			}
		},
	},

	created() {
		if (this.$route.query.user?.length) {
			// console.log(this.users)
			// let cuid = this.users.find(user => user.userId == this.$route.query.user).cuid
			// useBackofficeStore().fetchUserActivityByCuid(cuid)
			// console.log(this.users.find(user => user.userId == this.$route.query.user))

		}
	},
}
</script>

<style scoped>
	select {
		appearance: auto;
	}

	.filters {
		display: flex;
		gap: 8px;
		align-items: stretch;
	}

	.filter {
		display: flex;
	}
</style>
