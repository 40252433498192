import { defineStore } from 'pinia'
export const useBackofficeStore = defineStore('app', {
	state: () => ({
		user: {
			isLoggedIn: undefined
		},
		users: [],
		activity: [],
		loading: false,
	}),

	getters: {
	},

	actions: {
		fetchUsers() {
			this.loading = true;
			fetch(process.env.VUE_APP_URL_API + 'api/user/list', {
				method: 'GET',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
			})
				.then(response => response.json())
				.then(data => {
					if (data.message) { // provisorio!
						this.users = []
					}
	
					else {
						this.users = data
					}
				})
				.then(this.loading = false)
				.catch(error => console.error('Error:', error))
		},

		fetchUserActivity() {
			this.loading = true;
			fetch(process.env.VUE_APP_URL_API + 'api/logActivity/app', {
				method: 'GET',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
			})
			.then(response => response.json())
			.then(data => {
				if (data.message) { // provisorio!
					this.activity = []
				}

				else {
					this.activity = data
				}
			})
			.then(this.loading = false)
		},

		// fetchUserActivityByCuid(cuid) {
		// 	this.loading = true;
		// 	fetch(process.env.VUE_APP_URL_API + 'api/logActivity/app/getByUser/' + cuid, {
		// 		method: 'GET',
		// 		headers: new Headers({
		// 			'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
		// 			"Content-Type": "application/json",
		// 		}),
		// 	})
		// 		.then(response => response.json())
		// 		.then(data => this.userActivityByCuid = data)
		// 		.then(this.loading = false)
		// 		.catch(error => console.error('Error:', error))
		// },

		fetchUserByCuid(cuid) {
			this.loading = true;
			fetch(process.env.VUE_APP_URL_API + 'api/user/findByCuid/' + cuid, {
				method: 'GET',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
			})
				.then(response => response.json())
				.then(data => this.userByCuid = data)
				.then(this.loading = false)
				.catch(error => console.error('Error:', error))
		}
	},
})