<template>
  <div class="backoffice-view list-view activity">
		<div class="page-header">
			<h1>Actividad</h1>
		</div>
		<div
			class="table-container"
		>
			<!-- <div class="table-tools">
				<input type="search" placeholder="Buscar un usuario" v-model="searchQuery">
			</div> -->
			<div
				v-if="activity.length == 0"
			>
				<p>No hay actividad.</p>
			</div>
			<table v-else>
				<thead>
					<th>Usuario</th>
					<th>Tipo de actividad</th>
					<th>Entidad</th>
					<th>ID de la entidad</th>
					<th>Fecha y hora</th>
				</thead>
				<tbody>
					<tr
						v-for="event in activity"
						:key="event.id"
					>
						<td>{{ event.userName }}</td>
						<td>{{ event.activity }}</td>
						<td>{{ event.entity }}</td>
						<td>{{ JSON.parse(event.value).articleId }}</td>
						<td>{{ getFormattedDateTime(event.creationDate) }}</td>
				</tr>
				</tbody>
			</table>
		</div>
  </div>
</template>

<script setup>
// import { useBackofficeStore } from '@/stores/index.js'
</script>

<script>
export default {
  name: 'BackofficeActivityList',
	data() {
		return {
			activity: []
		}
	},

	computed: {
	},

	methods: {
		getFormattedDateTime(datetime) {
			var split = datetime.split('T')
			return split[0] + ' ' + split[1].split('.')[0]
		}
	},

	created() {
		fetch(process.env.VUE_APP_URL_API + 'api/logActivity/backOffice', {
			method: 'GET',
			headers: new Headers({
				'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
				"Content-Type": "application/json",
			}),
		})
		.then(response => response.json())
		.then(data => data.length > 0 ? this.activity = data : this.activity = [])
		.catch(error => console.error('Error:', error))
	},

	mounted() {
	},


}
</script>
<style>
#app {
	display: flex;
}
</style>
