<template>
	<nav id="sidenav">
		<div id="navigation">
			<ul>
				<router-link
					:to="'/'"
				>
					<li>
						Inicio
					</li>
				</router-link>
				<router-link
					:to="{name: 'AppUsersList'}"
				>
					<li>
						Pacientes
					</li>
				</router-link>
				<router-link
					:to="{name: 'AppActivityList'}"
				>
					<li>
						Actividad (app)
					</li>
				</router-link>
				<router-link
					:to="{name: 'BackofficeActivityList'}"
				>
					<li>
						Actividad (BO)
					</li>
				</router-link>
			</ul>
		</div>
	</nav>
</template>

<script>
export default {
	name: 'SideNav'
}
</script>