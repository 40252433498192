import { createApp } from 'vue'
import { useBackofficeStore } from './stores/index.js'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

const pinia = createPinia()
const app = createApp(App)
app.use(pinia).use(router)
app.mount('#app')

const store = useBackofficeStore();
store.$subscribe((mutation, state) => {
	if (state.user.isLoggedIn !== undefined) {
		localStorage.setItem('tita-localserver', JSON.stringify(state.user));
	}
})

const storedData = localStorage.getItem('tita-localserver');
if (storedData) {
	store.$patch({user: JSON.parse(storedData)});
}
