<template>
  <div class="backoffice-view list-view activity">
		<div class="page-header">
			<div class="title">
				<h1>Actividad</h1>
			</div>
			<div class="btn-container">
				<button
					class="btn btn-primary btn-lg"
					@click="syncData"
				>
					Sincronizar datos
				</button>
			</div>
		</div>
		<div class="loading" v-if="useBackofficeStore().loading"></div>
		<div
			class="table-container"
			v-else-if="activity"
		>
			<div class="table-tools">
				<div class="filters">
					<div class="filter">
						<select v-model="filters.filterEventType">
							<option value="undefined" selected>Tipo de actividad</option>
							<option
								v-for="event in eventTypes"
								:key="event"
								:value="event"
							>{{ event }}</option>
						</select>
					</div>
					<div class="filter">
						<select v-model="filters.filterEntityId">
							<option value="undefined" selected>ID de entidad</option>
							<option
								v-for="id in entityIds"
								:key="id"
								:value="id"
							>{{ id }}</option>
						</select>
					</div>
					<div class="filter">
						<input type="date" v-model="filters.filterDate">
					</div>
				</div>
			</div>
			<table v-if="filteredData.length > 0">
				<thead>
					<th>DNI</th>
					<th>Tipo de actividad</th>
					<th>Timestamp video / <br>permanencia artículo</th>
					<th>
						Entidad
						<!-- <button @click="sort.entity = !sort.entity">sort</button> -->
					</th>
					<th>ID de la entidad</th>
					<th>
						Fecha
						<!-- <button @click="sort.date = !sort.date">sort</button> -->
					</th>
					<th>Hora</th>
				</thead>
				<tbody>
					<tr
						v-for="event in filteredData"
						:key="event.id"
					>
						<td>
							<router-link
								:to="{
									name: 'UserActivityList',
									query: {
										user: event.user
									}
								}"
							>
								{{ dniById(event.user) }}
							</router-link>
						</td>
						<td>{{ event.activity }}</td>
						<td>{{ event.entity == 'Articulo' ? JSON.parse(event.value).read : event.entity == 'Video' ? event.value : '' }}</td>
						<td>{{ event.entity }}</td>
						<td>{{ event.entityId }}</td>
						<td>{{ getFormattedDate(event.creationDate) }}</td>
						<td>{{ getFormattedTime(event.creationDate) }}</td>
				</tr>
				</tbody>
			</table>
			<div v-else>
				<p>No hay resultados.</p>
				<!-- <div
					v-if="activity.length == 0"
				>
					<p>No hay actividad.</p>
				</div>
				<div
					v-else-if="activity.length > 0 && filteredData.length == 0"
				>
					
				</div> -->
			</div>
		</div>
  </div>
</template>

<script setup>
import { useBackofficeStore } from '@/stores/index.js'
</script>

<script>
export default {
  name: 'AppActivityList',
	data() {
		return {
			filters: {
				filterEventType: undefined,
				filterDate: undefined,
				filterEntityId: undefined
			},

			sort: {
				date: false,
				entity: false
			}
		}
	},

	computed: {
		activity() {
			return useBackofficeStore().activity
		},

		filteredData() {
			var filteredActivity = this.activity
			if (this.filters.filterEventType) {
				filteredActivity = filteredActivity.filter((act) => act.activity == this.filters.filterEventType)
			}

			if (this.filters.filterDate) {
				filteredActivity = filteredActivity.filter((act) => act.creationDate?.split('T')[0] == this.filters.filterDate)
			}

			if (this.filters.filterEntityId) {
				filteredActivity = filteredActivity.filter((act) => act.entityId == this.filters.filterEntityId)
			}

			if (this.sort.date) {
				filteredActivity = filteredActivity.toSorted((a,b) => new Date(a.creationDate) - new Date(b.creationDate))
			}

			if (this.sort.entity) {
				filteredActivity = filteredActivity.toSorted((a,b) => {
					a = a.entity.toUpperCase()
					b = b.entity.toUpperCase()
					return a < b ? 1 : a > b ? -1 : 0
				})
			}

			return filteredActivity
    },

		users() {
			return useBackofficeStore().users
		},

		eventTypes() {
			return [...new Set(useBackofficeStore().activity?.map(act => act.activity))]
		},

		entityIds() {
			return [...new Set(useBackofficeStore().activity?.map(act => act.entityId))]
		}
	},

	methods: {
		dniById(id) {
			return this.users.find(user => user.userId == id)?.dni
		},

		getFormattedDate(datetime) {
			if (datetime) {
				var split = datetime.split('T')
				return split[0]
			}
		},

		getFormattedTime(datetime) {
			if (datetime) {
				var split = datetime.split('T')
				return split[1].split('.')[0]
			}
		},

		syncData() {
		fetch(`${process.env.VUE_APP_URL_SYNC}20240101`, {
			method: 'GET',
			headers: new Headers({
				'Authorization': 'Basic ' + btoa(`${process.env.VUE_APP_AUTH_USER}:${process.env.VUE_APP_AUTH_TOKEN}`),
			}),
		})
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json(); // Cambiado a .json() para procesar como JSON
		})
		.then(data => {
			console.log('Data:', data); // Aquí puedes trabajar con el JSON recibido
		})
		.catch(error => console.error('Error:', error));
	}

	},

	created() {
	},

	mounted() {
	},


}
</script>
<style scoped>
	select {
		appearance: auto;
	}

	.filters {
		display: flex;
		gap: 8px;
		align-items: stretch;
	}

	.filter {
		display: flex;
	}
</style>
