<template>
	<TopNav
		v-if="useBackofficeStore().user.isLoggedIn"
		:username="useBackofficeStore().user.username"
		@logout="logout"
	/>
	<SideNav
		v-if="useBackofficeStore().user.isLoggedIn"
	/>
  <router-view/>
</template>

<script setup>
import { useBackofficeStore } from '@/stores/index.js'
</script>
<script>
import SideNav from '@/components/SideNav.vue'
import TopNav from '@/components/TopNav.vue'
export default {
	components: {
		SideNav,
		TopNav
	},

	methods: {
		logout() {
			useBackofficeStore().user.isLoggedIn = false
			this.$router.push({name: 'Login'})
		}
	},

	created() {
		useBackofficeStore().fetchUsers()
		useBackofficeStore().fetchUserActivity()
	}
}
</script>

<style>
@import url(/tita-assets/dist/tita.css);
@import url(./assets/backoffice.scss);
</style>