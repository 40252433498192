import { createRouter, createWebHashHistory } from 'vue-router'
import { useBackofficeStore } from '@/stores/index.js'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import AppActivityList from '@/views/AppActivityList.vue'
import AppUsersList from '@/views/AppUsersList.vue'
import BackofficeActivityList from '@/views/BackofficeActivityList.vue'
import UserActivityList from '@/views/UserActivityList.vue'
import AppUserCreate from '@/views/AppUserCreate.vue'

const routes = [
  {
    path: '',
    name: 'Home',
    component: HomeView
  },
	{
		path: '/login',
		name: 'Login',
		component: LoginView
	},
	{
		path: '/users',
		children: [
			{
				path: '',
				name: 'AppUsersList',
				component: AppUsersList,
			},
			{
				path: 'activity',
				name: 'UserActivityList',
				component: UserActivityList
			},
			{
				path: 'data',
				name: 'AppUserCreate',
				component: AppUserCreate
			},
		]
	},
	{
		path: '/activityAll',
		name: 'AppActivityList',
		component: AppActivityList
	},
	{
		path: '/activityBackofficeAll',
		name: 'BackofficeActivityList',
		component: BackofficeActivityList
	}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to) => {
	if (!useBackofficeStore().user.isLoggedIn && to.name !== 'Login') {
    return { name: 'Login' }
  }

	return true
})

export default router
