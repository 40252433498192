<template>
	<div class="backoffice-view user-data">
		<div class="page-header">
			<h1>{{ mode == 'create' ? 'Agregar paciente' : user.username }}</h1>
		</div>
		<main>
			<form>
				<div class="form-field-vertical">
					<label>CUID</label>
					<input type="number" v-model="user.cuid">
				</div>
				<div class="form-field-vertical">
					<label>Nombres</label>
					<input type="text" v-model="user.name">
				</div>
				<div class="form-field-vertical">
					<label>Apellidos</label>
					<input type="text" v-model="user.lastName">
				</div>
				<div class="form-field-vertical">
					<label>DNI/Pasaporte</label>
					<input type="number" v-model="user.dni">
				</div>
				<div class="form-field-vertical">
					<label>Fecha de nacimiento</label>
					<!-- <VueDatePicker
						v-model="user.birthDate"
						:max-date="new Date()"
						:enable-time-picker="false"
						:select-text="'Aplicar'"
						:cancel-text="'Cancelar'"
						:locale="'es-AR'"
						:day-names="['dom', 'lun', 'mar', 'mie', 'jue', 'vie', 'sab']"
						:format="'DD/MM/YYYY'"
						text-input
					/> -->
					<input type="date" v-model="user.birthDate">
				</div>
				<div class="form-field-vertical">
					<label>Teléfono</label>
					<input type="number" v-model="user.phone" placeholder="0012345677">
				</div>
				<div class="form-field gap8">
					<input type="checkbox" id="patientConsentCheckbox" v-model="user.consent">
					<label for="patientConsentCheckbox">
						Consentimiento informado
					</label>
				</div>
				<div class="btn-container">
					<button
						type="button"
						class="btn btn-primary btn-lg"
						@click="saveUserChanges()"
						:disabled="!isFormValid"
					>
						{{ mode == 'create' ? 'Agregar paciente' : 'Guardar cambios' }}
					</button>
				</div>
			</form>
		</main>
	</div>
</template>

<script>
// import VueDatePicker from '@vuepic/vue-datepicker'
// import '@vuepic/vue-datepicker/dist/main.css'
export default {
	name: 'AppUserCreate',
	// components: {VueDatePicker},
	data() {
		return {
			user: {
				userId: '',
				cuid: '',
				name: '',
				lastName: '',
				birthDate: undefined,
				phone: '',
				dni: '',
				consent: false,
				hasValidEmail: false,
			},
			mode: undefined,
		}
	},

	computed: {
		isFormValid() {
			for (const key in this.user) {
				if (key == 'userId' || key == 'hasValidEmail' || key == 'lastTestDate' || key == 'password' || key == 'nickname') {
					continue
				}

				if (!this.user[key]) {
					return false
				}
			}

			return true
		}
	},

	methods: {
		onUserUpdated() {
			this.$router.push({name: 'AppUsersList'})
		},

		saveUserChanges() {
			let url;
			if (this.mode == 'edit') url = 'update'
			else if (this.mode == 'create') url = 'new'

			// let userWithFormattedDate = this.user
			// userWithFormattedDate.birthDate = this.formattedBirthDate

			fetch(process.env.VUE_APP_URL_API + 'api/user/' + url, {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
				body: JSON.stringify(this.user)
			})
				.then(response => response.json())
				.then(data => console.log('user saved', data.userId))
				.catch(error => console.error('Error:', error));

			this.$router.push({name: 'AppUsersList'})
		}
	},

	created() {
		if (this.$route.query.cuid) {
			this.mode = 'edit'
			fetch(process.env.VUE_APP_URL_API + `api/user/findByCuid/${this.$route.query.cuid}`, {
				method: 'GET',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
			})
				.then(response => response.json())
				.then(data => this.user = data)
				.catch(error => console.error('Error:', error));
		}

		else {
			this.mode = 'create'
		}
	}
}
</script>